import {createRouter, createWebHistory} from 'vue-router'
const routerHistory = createWebHistory()

const routes = [
  //主页
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/home1',
    name: 'home1',
    component: () => import('../views/Home1.vue')
  },
  //完成
  {
    path: '/finish',
    component: () => import('../views/finish.vue'),
  },
  //页面选择
  {
    path: '/change',
    component: () => import('../views/change.vue'),
  },
  //报名页面
  {
    path: '/application',
    component: () => import('../views/application.vue'),
  },
  {
    path:'/',
    redirect:'/home1',
  }
]

// 创建路由器
const router = createRouter({
  history: routerHistory,
  routes: routes
})

export default router