import { createStore } from 'vuex'

export default createStore({
  state: {
    token:'511302199904110319',
    openid:'',
    message:{}
  },

  mutations: {
    MESSAGE(state,message){
      state.message = message
    }
  },
  actions: {
  },

})
